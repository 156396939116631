<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("admin.config.title") }}</span>
    </template>

    <template #default>
      <crud-list
        :show-state-column="false"
        :show-id-column="false"
        :show-select-column="false"
        :show-ordering-column="false"
        :create-route="false"
        :api-get-url="'config/list'"
        :allow-delete="false"
        :allow-create="false"
        :api-update-url="getUpdateApiUrl"
        :edit-route="getEditRoute"></crud-list>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import { useStore } from "vuex";

import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
//    import paginationComposition  from '@/client/extensions/composition/pagination.js';
import CrudList from "@/client/components/crud/List.vue";
import { computed, reactive, getCurrentInstance } from "vue";

import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  components: { CrudList },
  props: {
    entityType: {
      type: String,
      default: "default",
    },
  },
  setup(props, setupContext) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(
      props,
      useStore()
    );
    let { t } = useI18n();
    let { meta } = useMeta({
      title: t("admin.config.title"),
    });
    return { asyncOps, asyncOpsReady, asyncStatus, meta };
  },

  data: function () {
    return {};
  },
  computed: {},
  methods: {
    getEditRoute(id, item) {
      return { name: "admin-config-edit", params: { entryKey: item.entryKey } };
    },
    getUpdateApiUrl(arg) {
      return "config/" + arg;
    },
  },
};
</script>

<style scoped lang="scss">
.meta-column-header {
  white-space: nowrap;
}

.list-column-header {
  position: relative;
  cursor: pointer;

  .col-sort-indicator {
    position: absolute;
    top: calc(50% + 1px);
    left: -5px;
    transform: translateY(-50%);
    z-index: 5;
    display: inline-block;
  }
}
</style>
